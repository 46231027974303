import React from 'react'
import {
  Switch,
  Route,
  BrowserRouter as Router
} from 'react-router-dom'

import ScrollToTop from './ScrollToTop';
import James from "./James";
import IntakeForm from "./intake/IntakeForm"
import Communication from "./communication/CommunicationMain"
import Psyllium from './Psyllium';

const Routes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        <Route exact path='/' component={James} />
        <Route exact path="/intake" component={IntakeForm} />
        <Route exact path="/communication" component={Communication} />
        <Route exact path="/psyllium" component={Psyllium} />
        <Route render={() => <James/>} />
      </Switch>
    </ScrollToTop>
  </Router>
)

export default Routes
