import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import './IntakeForm.css';

export default function IntakeForm() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const endpoint = "https://vef8xzw879.execute-api.us-east-2.amazonaws.com/default/sendIntakeEmail";
  const [showSent, setShowSent] = useState(false);
  const onSubmit = data => {const stringData = JSON.stringify(data);
                            const requestOptions = {
                              method: "POST",
                              body: stringData
                            };

                            fetch(endpoint, requestOptions)
                              .then((response) => {
                                if (!response.ok) throw new Error("Error in fetch");
                                console.log(response);
                                return response.json();
                              })
                              .then((response) => {
                                console.log("Email sent successfully!");
                                setShowSent(!showSent);
                              })
                              .catch((error) => {
                                console.log(error, "Unknown Error Caught");
                              });
                            };
                            // console.log(errors);

  return (
    <div>
      <h1 className="h1">Medical Intake Form</h1>
      {!showSent && (
      <form onSubmit={handleSubmit(onSubmit)}>
      <input type="text" placeholder="Pet's Name" {...register("pet", {required: true, maxLength: 80})} />
      <input type="text" placeholder="Last Name" {...register("lastName", {required: true, maxLength: 100})} />
      <label><strong>Why are you here today?</strong>  Please describe when the problem started and what you have noticed since.  Include any additional goals you have for today’s visit.
      <textarea {...register("reason", {required: true, maxLength: 2500})} />
      </label>
      <label>
      Does your pet have any <strong>significant medical history</strong>?
      <textarea {...register("medHistory", {maxLength: 1500})} />
      </label>
      <label>
      What <strong>medications</strong> is your pet currently on?
      <textarea {...register("medications", {maxLength: 1500})} />
      </label>
      <label>
      What heartworm preventative is your pet taking routinely?
      <input type="text" placeholder="Heartgard, Proheart, Revolution Plus, None" {...register("heartworm", {})} />
      </label>
      <label>
      What flea and tick medication does your pet take routinely?
      <input type="text" placeholder="Simparica, Bravecto, Nexgard, Advantage Multi" {...register("fleaTick", {})} />
      </label>
      <h2 className="h2"></h2>
      <h2 className="h2">Please select which <strong>vaccines</strong> your pet is up to date on:</h2>
      <h3 className="h3">If you are not sure if your pet has been vaccinated recently enough to be considered up to date, leave "Not Sure" selected.</h3>
      <label>
      Parvovirus (DAP, Distemper Adenovirus Parvovirus)
      <select {...register("parvo")}>
        <option value="Not Sure">Not Sure</option>
        <option value="Vaccinated">Vaccinated</option>
        <option value="Not Vaccinated">Not Vaccinated</option>
        <option value="NA">I am here with a cat</option>
      </select>
      </label>
      <label>
      Leptospirosis (Lepto)
      <select {...register("lepto")}>
        <option value="Not Sure">Not Sure</option>
        <option value="Vaccinated">Vaccinated</option>
        <option value="Not Vaccinated">Not Vaccinated</option>
        <option value="NA">I am here with a cat</option>
      </select>
      </label>
      <label>
      Bordetella (sometimes called "Kennel Cough")
      <select {...register("bordetella")}>
        <option value="Not Sure">Not Sure</option>
        <option value="Vaccinated">Vaccinated</option>
        <option value="Not Vaccinated">Not Vaccinated</option>
        <option value="NA">I am here with a cat</option>
      </select>
      </label>
      <label>
      Rabies (Rabies)
      <select {...register("rabies")}>
        <option value="Not Sure">Not Sure</option>
        <option value="Vaccinated">Vaccinated</option>
        <option value="Not Vaccinated">Not Vaccinated</option>
      </select>
      </label>
      <label>
      FVRCP (Feline Viral Rhinotracheitis, Calicivirus, Panleukopenia)
      <select {...register("fvrcp")}>
        <option value="Not Sure">Not Sure</option>
        <option value="Vaccinated">Vaccinated</option>
        <option value="Not Vaccinated">Not Vaccinated</option>
        <option value="NA">I am here with a dog</option>
      </select>
      </label>
      <label>
      FeLV (Feline Leukemia Virus)
      <select {...register("felv")}>
        <option value="Not Sure">Not Sure</option>
        <option value="Vaccinated">Vaccinated</option>
        <option value="Not Vaccinated">Not Vaccinated</option>
        <option value="FeLV Positive">My cat is FeLV positive</option>
        <option value="NA">I am here with a dog</option>
      </select>
      </label>
      <label>
      If your pet is a cat, is it indoor only?
      <select {...register("indoor")}>
        <option value=""></option>
        <option value="Yes">Yes</option>
        <option value="No">No (Indoor/Outdoor or Outdoor)</option>
        <option value="NA">I am here with a dog</option>
      </select>
      </label>
      <label>
      Describe your pet’s diet (food brand and type) and appetite.
      <textarea {...register("diet", {maxLength: 1500})} />
      </label>
      <label>
      Does your pet have any known allergies to medications or food?
      <input type="text" placeholder="Allergies" {...register("allergies", {})} />
      </label>
      <h3 className="h3">The following questions help us prevent the spread of infectious disease in our hospital:</h3>
      <label>
      Has your pet had any coughing or sneezing?
      <select {...register("cough")}>
        <option value=""></option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      </label>
      <label>
      Has your pet had any vomiting?
      <select {...register("vomiting")}>
        <option value=""></option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      </label>
      <label>
      Has your pet had any diarrhea?
      <select {...register("diarrhea")}>
        <option value=""></option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      </label>
      <label>
      Does your pet have any open wounds or skin infections?
      <select {...register("wounds")}>
        <option value=""></option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      </label>
      <label>
      Core diagnostic tests for most sick patients include basic blood work (CBC, comprehensive chemistry).  The cost of a CBC and Chemistry panel will be in addition to the cost of your visit today.  Additional tests, including additional specific blood tests, may be recommended by the veterinarian.
 If indicated, based on the judgement of one of our veterinarians, would you like us to start blood work?
      <select {...register("bloodWorkApproved")}>
        <option value=""></option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      </label>
      <h3 className="h3">By selecting "Yes", you approve basic blood work.  Select "No" if you would like to speak to a veterinarian before any diagnostic testing.</h3>
      <input type="submit" value="Submit"/>
      </form>
      )}
      {showSent && (
      <h1 className='h1' style={{ borderBottom: '0' }}>Thank you!</h1>)}

    </div>
  );
}
