import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
    textAlign: 'center',
    display: 'block',
    margin: 'auto',
  },
  centerp: {
    textAlign: 'center',
    display: 'block',
    margin: 'auto',
    paddingBottom: '15px',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '95vw',
    height: '315px',
    maxWidth: '560px',
    maxHeight: '315px',
    margin: 'auto',
  },
  tempVid: {
    diplay: 'block',
    margin: 'auto',
    width: '100%',
    height: '100%',
  },
});

class CommunicationMain extends Component {

  componentWillMount() {
    document.title = "Communication";
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
      <div className={classes.twelvehundred}>
        <div className={classes.nintysix}>
          <h1>Communication Journey</h1>
          <p className={classes.justify}>A stepwise video workbook through the Calgary Cambridge guide to medical communication.
          Our goal here is to improve patient care through shared decision making with the pet parent.</p>

          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Welcome to Kaua'i North Shore</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.fullwidth}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <div className={classes.tempDiv}>
                    <iframe className={classes.tempVid} src="https://www.youtube.com/embed/fIgBDo3fC1E" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.centerp}>Aloha! Check out our communication videos brah.</Typography>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Prepare Yourself</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.fullwidth}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <div className={classes.tempDiv}>
                    <iframe className={classes.tempVid} src="https://www.youtube.com/embed/3vVuHOuRqLs" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.centerp}>Breathe, get ready, set...</Typography>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Introduce Yourself</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.fullwidth}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <div className={classes.tempDiv}>
                    <iframe className={classes.tempVid} src="https://youtube.com/embed/BuwWCptMEy8" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.centerp}>Wag your tail a little. This is our first impression.</Typography>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Address Immediate Concerns</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.fullwidth}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <div className={classes.tempDiv}>
                    <iframe className={classes.tempVid} src="https://www.youtube.com/embed/KVCpDf1VBEo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.centerp}>We're 100% on time, every time, 80% of the time.</Typography>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>The Pet Parent's Agenda</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.fullwidth}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <div className={classes.tempDiv}>
                    <iframe className={classes.tempVid} src="https://www.youtube.com/embed/ztr86QREWm0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.centerp}>What is this human doing here?</Typography>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Summarize Their Agenda Back</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.fullwidth}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <div className={classes.tempDiv}>
                    <iframe className={classes.tempVid} src="https://www.youtube.com/embed/xRwuSauV394" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.centerp}>Now they know you know. You know they know you know. 'Cuz you told them you know.</Typography>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <Link className="" to="/"><i className={classes.iconButton} style={{marginTop: "40px",}}>home</i></Link>

          <p className={classes.justify}></p>
        </div>
      </div>
      </div>
      );
  }
}

CommunicationMain.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CommunicationMain);
