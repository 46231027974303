import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import Svg0 from './images/psyllium/0.svg';
import Svg1 from './images/psyllium/1.svg';
import Svg2 from './images/psyllium/2.svg';
import Svg3 from './images/psyllium/3.svg';
import Svg4 from './images/psyllium/4.svg';
import Svg5 from './images/psyllium/5.svg';
import Svg6 from './images/psyllium/6.svg';
import Svg7 from './images/psyllium/7.svg';
import Svg8 from './images/psyllium/8.svg';
import Svg9 from './images/psyllium/9.svg';
import Svg10 from './images/psyllium/10.svg';
import Svg11 from './images/psyllium/11.svg';
import Svg12 from './images/psyllium/12.svg';
import Svg13 from './images/psyllium/13.svg';
import Svg14 from './images/psyllium/14.svg';
import Svg15 from './images/psyllium/15.svg';
import Svg16 from './images/psyllium/16.svg';
import Svg17 from './images/psyllium/17.svg';
import Svg18 from './images/psyllium/18.svg';
import Svg19 from './images/psyllium/19.svg';
import Svg20 from './images/psyllium/20.svg';

import './App.css';

const svgs = [Svg0, Svg1, Svg2, Svg3, Svg4, Svg5, Svg6, Svg7, Svg8, Svg9, Svg10, Svg11, Svg12, Svg13, Svg14, Svg15, Svg16, Svg17, Svg18, Svg19, Svg20]

class Psyllium extends Component {
  render() {
    return (
      <div style={{backgroundColor: 'black'}}>
        <Grid container spacing={0} style={{maxWidth: '700px', display: 'block', margin: 'auto'}}>
        {svgs.map((svg, i) => (
          <Grid item xs={12} key={i}>
          <img src={svg} alt={"page"+i} />
          </Grid>
        ))}
        </Grid>
      </div>
       
    );
  }
}

export default Psyllium;
